<template>
    <div class="page">
        <div class="content">
            <div class="page-title bg-white">
                <h1 class="typography-h1 title bg-white">
                    Můj účet
                </h1>
                <q-btn flat class="my-btn logout" @click="logOut">
                    <span class="typography-h3">Odhlásit se</span>
                        <q-icon class="icon-grey" name="svguse:/icons.svg#sign-out"/>
                </q-btn>
            </div>
            <q-form
                ref="form"
                autocomplete="off"
                @submit.prevent="save"
                class="box"
            >
                <div class="validations">
                    <div class="tag" v-for="error in errors" :key="error.key">
                        {{error.message}}
                    </div>

                </div>
                <div class="row box-inner">
                    <div class="col-3">
                        <q-input
                            @input="disabled=false"
                            autofocus
                            v-model="myAccount.firstName"
                            class="my-input my-input--square my-input--square full-width"
                            label="Jméno" type="text"
                            :error="!!errors.find(err => err.key === 'firstName')"
                        />
                    </div>
                    <div class="col-3">
                        <q-input
                            @input="disabled=false"
                            v-model="myAccount.lastName"
                            class="my-input my-input--square my-input--square full-width"
                            label="Příjmení" type="text"
                            :error="!!errors.find(err => err.key === 'lastName')"
                        />
                    </div>
                    <div class="col-3">
                        <q-input
                            @input="disabled=false"
                            v-model="myAccount.email"
                            class="my-input my-input--square my-input--square full-width"
                            label="E-mail" type="email"
                            :error="!!errors.find(err => err.key === 'email')"
                        />
                    </div>
                    <div class="col-3">
                        <input type="password" id="password" class="hidden" v-model="myAccount.password">
                        <q-input
                            @input="disabled=false"
                            v-model="myAccount.password"
                             class="my-input my-input--square my-input--square no-autofill full-width"
                             label="Nové heslo"
                             @click="hidePassword=false"
                             @focusout="hidePassword=true"
                             :readonly="hidePassword"
                             :type="showPassword ? 'text' : 'password'"
                             :error="!!errors.find(err => err.key === 'password')"
                        >

                            <template #append>
                                <q-icon name="visibility"
                                        @mousedown="showPassword = true"
                                        @mouseup="showPassword = false"
                                        class="cursor-pointer">
                                </q-icon>
                            </template>
                        </q-input>
                    </div>
                </div>
                <div class="flex justify-end q-mt-lg q-pr-md">
                    <q-btn type="submit" style="min-width: 165px;"
                           class="my-btn my-btn--primary"
                           color="black"
                           :disable="disabled"
                           :label=" $t('update')"/>
                </div>
            </q-form>

        </div>
    </div>
</template>

<script>
export default {
    data: ()=> ({
        disabled: true,
        myAccount: {
            firstName: '',
            lastName: '',
            email: '',
            password: 'secret'
        },
        showPassword: false,
        hidePassword: true,
        errors:[]
    }),

    methods: {
        async save() {
            this.errors.length=0;
            if(!this.myAccount.firstName) this.errors.push({
                key: 'firstName',
                message: this.$t('validation.required-name')
            });
            if(!this.myAccount.lastName) this.errors.push({
                key: 'lastName',
                message: this.$t('validation.required-lastname')
            });
            if(this.myAccount.password && this.myAccount.password.length < 6) {
                this.errors.push({
                    key: 'password',
                    message: this.$t('validation.password')
                });
            }
            if(this.isEmail(this.myAccount.email) === false) {
                this.errors.push({
                    key: 'email',
                    message: this.$t('validation.valid-email')
                });
            }
            if(this.errors.length){
                return;
            }

            this.myAccount = (await axios.put('/account', this.myAccount)).data;
            this.disabled = true;
            notifyOk("Vaše údaje byly uloženy.");
        },
    },
    mounted() {
        this.disabled = true;
        this.$refs.form.attributes.autocomplete = 'off';
        this.$refs.form.querySelector('input[type="password"]').setAttribute('autocomplete', 'off');
    },

    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
        this.myAccount = (await axios.get('/account')).data;
    },

    meta: {
        title: 'Můj účet',
    },
};
</script>
<style lang="scss" scoped>
.page{
    overflow: hidden;
    height: 100vh;
}
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    padding: 24px 16px;
    min-height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.box{
    padding: 16px 0 32px;
    margin: 32px 16px;
    background: #fff;
    border: 1px solid $black-300;
}
.box-inner{
    margin-left: -1px;
    margin-right: -1px;
}
.pass{
    display: none;
}
.logout{
    color: $black-700;
    display: flex;
    align-items: center;
    span{
        margin-right: 8px;
    }
}
.icon-grey{
    stroke: $black-700 !important;
}
.logout:hover{
    color: $black-900;
    .icon-grey{
        stroke: $black-900 !important;
    }
}
.validations{
    padding-left: 24px;
    margin-bottom: 16px;
    height: 24px;
    display: flex;
}
.tag{
    padding: 4px 8px;
    background: $red-200;
    color: $red-500;
}
.tag + .tag{
    margin-left: 8px;
}
</style>
